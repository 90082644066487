import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(protected http: HttpClient, private httpclient: HttpClient,) { }

  get(url){
    return this.http.get<any>(url);
  }
  remove(url){
    return this.http.delete<any>(url);
  }

  post(url, data)
  {
    return this.http.post<any>(url,JSON.stringify(data));
  }

  // put(url, data)
  // {
  //   return this.http.put<any>(url,JSON.stringify(data));
  // }

  put(url, data)
  {
    return this.httpclient.put(url, data);
  }

  // public login(loginData) {
  //   return this.httpclient.put(this.PATH_OF_API + '/624c2ecb6227cb7be9e0644b', loginData, {
  //     headers: this.requestHeader,
  //   });
  // }
}
