import { API_URL } from "../shared/services/app-config";

export let allUrl = {
  buildings: {
    add: API_URL + 'building',
    list: API_URL + 'building?vacant=true',
    listall: API_URL + 'building'
    
  },
  announcements: {
    add: API_URL + 'announcement',
    list: API_URL + 'announcement',
  },
  users: {
    add: API_URL + 'users/register',
    list: API_URL + 'users',
    edit: API_URL + 'users/',
    editmine: API_URL + 'users/profile',
    getuserbyid: API_URL + 'users/',
    deactivate: API_URL + 'users/'
  },
  visitortypes: {
    add: API_URL + 'visitortypes',
    list: API_URL + 'visitorTypes',
  },
  artisancategory: {
    add: API_URL + 'artisan-categories',
    list: API_URL + 'artisan-categories',
  },
  panic: {
    list: API_URL + 'help',
  },
  recommendation: {
    add: API_URL + 'recommendation',
    list: API_URL + 'recommendation',
    listall: API_URL + 'recommendation/all',
  },
  resident: {
    add: API_URL + 'resident',
    list: API_URL + 'resident',
    listall: API_URL + 'residents',
  },
  residenttypes: {
    add: API_URL + 'residentTypes',
    list: API_URL + 'residentTypes',
  },
  visitor: {
    add: API_URL + 'visitor',
    list: API_URL + 'visitor',
    getbyid: API_URL + 'visitor/',
    listall: API_URL + 'visitor/all',
  },
  constitution: {
    fetch: API_URL + "constitution",
    create: API_URL + "constitution",
    update: API_URL + "constitution"
  },
    dues: {
    fetch: API_URL + "dues",
    create: API_URL + "dues",
    update: API_URL + "dues/"
  },
      artisan: {
    fetch: API_URL + "artisans",
    create: API_URL + "dues",
    update: API_URL + "dues"
  },
};

