import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';


@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private auth: AuthService, private _router: Router) { }

  canActivate(): boolean {
    let routess = ['/forgetPassword']
    console.log(this.auth.loggedIn());
   
    if (this.auth.loggedIn()) {
      return true;
    }
    else if((window.location.pathname  === '/forgetPassword')){
      
      this._router.navigate(['/pages/forgetPassword']);
      return false;
    }
       else if((window.location.pathname  === '/resetPassword')){
      
      this._router.navigate(['/pages/resetPassword']);
      return false;
    }
    else {
      this._router.navigate(['/pages/login']);
      return false;
    }
  }
}
